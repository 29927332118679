import { Box, Divider, Grid, Typography } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Links from '@material-ui/core/Link'
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined'
import { useLocation } from '@reach/router'
import { Link, navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { isIOS, isMacOs } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share'
import { generateTrackingLink } from '../../utils/brandsHelper'
import { TrackingCase } from '../../utils/trackingCases'
import { useAppState } from '../appState'
import clipboard from '../assets/images/clipboard.svg'
import emailIcon from '../assets/images/dark-email.svg'
import facebookIcon from '../assets/images/facebook.svg'
import pinterest from '../assets/images/pinterest.svg'
import twiterIcon from '../assets/images/twitter.svg'
import axios from '../axios'
import CreateProductShareLink from '../components/createProductShareLink'
import SEO from '../components/seo'
import SignInModal from '../components/signInModal'
import SocialShareLink from '../components/SocialShareLink'
import Spinner from '../components/spinner'
import { ENDPOINTS, ROUTES } from '../constants'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/product'
import { tracker } from '../systemLogs'
import NotFound from './404'

declare const window: any

const ProductView = ({ t, productId }) => {
  const classes = styles()
  const [product, setProduct] = useState({})
  const [shortenedLink, setShortenedLink] = useState({
    trackingLink: '',
    value: '',
    commission: '',
    isLoading: false,
  })
  const [added, setAdded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [appState] = useAppState()
  const trendingId = new URLSearchParams(useLocation().search).get('trending')
  const [error, setError] = useState('')

  useEffect(() => {
    appState.userId && setIsLoggedIn(true)
  }, [])

  useEffect(() => {
    getProduct()
  }, [productId])

  const getProduct = async () => {
    setLoading(true)
    try {
      let result = null
      if (trendingId) {
        const response = await axios.get(`${ENDPOINTS.trending}/${trendingId}`)
        result = response.data.data[0].data
        result.productId = productId
      } else {
        const response = await axios.get(
          ENDPOINTS.productById.replace('{id}', productId)
        )
        result = response.data.data
        if (result === undefined) {
          navigate(ROUTES.error)
        }
      }
      if (result) {
        const trackingLink = generateTrackingLink(
          {
            network: result.network_id,
            trackingLink: result.tracking_url,
            url: result.product_url,
            brandName: result.brand_name,
            commission: result.cashBack,
          },
          {
            userId: `${appState.userId || 0}`,
            userName: appState.userProfile?.userName,
          },
          result.product_id,
          '',
          Boolean(appState.userId)
        )

        setProduct({
          ...result,
          trackingLink,
          images_urls: [result.thumbnail_url, ...result.images_urls],
        })
        setAdded(Boolean(result.shoppingBoardId))
      }
    } catch (err) {
      navigate(ROUTES.error)
      // setError(t('messages.somethingWentWrong'))
    }
    setLoading(false)
  }

  const handleAddToShoppingBoard = async () => {
    if (!isLoggedIn) {
      setIsOpenSignInModal(true)
      return
    }
    try {
      setAdded(true)
      tracker.track(
        TrackingCase.UserTracking,
        `POST ${ENDPOINTS.addToShoppingBoard}`,
        {
          location: 'ProductView',
          brandId: product.brand_id,
          productId: product.product_id,
        }
      )
      await axios.post(ENDPOINTS.addToShoppingBoard, {
        brandId: product.brand_id,
        type: 'product',
        productId: product.product_id,
        data: {
          name: product.name,
          imageUrl: product.thumbnail_url,
          price:
            isNaN(product.price) || String(product.price) === ''
              ? `${product.price}`
              : '$' + product.price.toFixed(2),
          originalPrice:
            isNaN(product.original_price) ||
            String(product.original_price) === ''
              ? `${product.original_price}`
              : '$' + product.original_price.toFixed(2),
          brandId: product.brand_id,
          brandName: product.brand_name,
          networkId: product.network_id,
          link: product.product_url,
          tracking_url: product.tracking_url,
        },
      })
      if (appState.username) {
        window.itemsFavourite(
          product.name.replace(/"/g, ''),
          {
            name: product.name.replace(/"/g, ''),
            imageUrl: product.thumbnail_url,
            price: product.price,
            brandId: product.brand_id,
            brandName: product.brand_name,
            networkId: product.network_id,
            link: product.product_url,
            tracking_url: product.tracking_url,
          },
          (product.price + '').replace(/\$/g, '')
        )
      }
    } catch (err) {
      setTimeout(() => setAdded(false), 500)
    }
  }

  const handleSMSButtonClick = () => {
    if (isIOS || isMacOs) {
      window.open(
        `sms:?&body=${t('createLink.smsMessage') + shortenedLink.value}`,
        '_self'
      )
    } else {
      window.open(
        `sms:?body=${t('createLink.smsMessage') + shortenedLink.value}`,
        '_self'
      )
    }
  }

  const closeSignInModal = () => {
    setIsOpenSignInModal(false)
  }

  if (loading) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    )
  }

  if (error || Object.keys(product).length === 0) {
    return <NotFound error={error} />
  }

  return (
    <Layout>
      <SEO
        title={t('seo.product.title')
          .replace('[Brand]', product.brand_name)
          .replace('[max %]', product.cashBack)
          .replace('[Product name]', product.name)}
        description={t('seo.product.description')
          .replace('[Brand]', product.brand_name)
          .replace('[max %]', product.cashBack)
          .replace('[Product name]', product.name)}
        klaviyo={{
          triggerName: 'Product Clicked',
          productId: product.product_id,
          productName: product.name,
        }}
      />
      <div className={classes.paper}>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12} justifyContent="center">
            <Breadcrumbs aria-label="breadcrumb">
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.home)
                }}
              >
                home
              </Links>
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.brands)
                }}
              >
                brands
              </Links>
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(
                    `${ROUTES.featuredBrands}${product.brand_name.replace(
                      /\s+|\/|\\|#/g,
                      '-'
                    )}/${product.brand_id}`
                  )
                }}
              >
                {product.brand_name.toLowerCase()}
              </Links>
              <Typography color="textPrimary">
                {' '}
                {product.name.toLowerCase()}
              </Typography>
            </Breadcrumbs>
            <Carousel
              swipeable={true}
              showArrows={false}
              showThumbs={false}
              emulateTouch={true}
              showStatus={false}
            >
              {product.images_urls.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image}
                      alt={product.name}
                      draggable={false}
                      className={classes.productImage}
                      style={{ pointerEvents: 'all' }}
                    />
                  </div>
                )
              })}
            </Carousel>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.productInfo}
          >
            <Typography variant="h2" className={classes.productInfoTitle}>
              {product.name}
            </Typography>
            <div className={classes.subTitle}>
              <Typography variant="h3">
                {t('shared.from')}{' '}
                <Link
                  to={`/featured-brands/${product.brand_name.replace(
                    /\s+|\/|\\|#/g,
                    '-'
                  )}/${product.brand_id}`}
                  className={classes.brandLink}
                >
                  {product.brand_name}
                </Link>
              </Typography>

              <Typography variant="h3" className={classes.productInfoPrice}>
                ${Number(product.price).toFixed(2)}
              </Typography>
            </div>

            <Typography
              component="h1"
              variant="h1"
              className={classes.seoHeading}
            >
              {product.isCashbackShownInWebsite ? (
                <>
                  {product.brand_name.toLowerCase() === 'revolve'
                    ? t('seo.featuredBrands.h1Conditional').replace(
                        '[Brand]',
                        product.brand_name
                      )
                    : t('seo.product.h1')
                        .replace('[Brand]', product.brand_name)
                        .replace('[max %]', product.cashBack)
                        .replace('[Product name]', product.name)}
                </>
              ) : (
                t('seo.featuredBrands.hideCashback').replace(
                  '[Brand]',
                  product.brand_name
                )
              )}
            </Typography>

            <Divider />
            <Typography
              variant="subtitle1"
              className={classes.productInfoDescription}
            >
              {product.description}
            </Typography>
            <a
              className={`${classes.button} ${classes.blackBgButton}`}
              target="_blank"
              href={product.trackingLink}
            >
              <Typography variant="button">{t('shared.shop')}</Typography>
            </a>
            <Divider />
            <Box mt={5} />
            <CreateProductShareLink
              networkId={product.network_id}
              productId={productId}
              productUrl={product.product_url}
              productName={product.name}
              productImage={product.thumbnail_url}
              shortenedLink={shortenedLink}
              setShortenedLink={s => setShortenedLink(s)}
              isLoggedIn={isLoggedIn}
              handleOpenModal={() => setIsOpenSignInModal(true)}
            />
            <button
              className={`${classes.button} ${classes.whiteBgButton} ${classes.addToShoppingBoard}`}
              onClick={handleAddToShoppingBoard}
              disabled={added}
            >
              <div className={classes.addToboardContainer}>
                <img src={clipboard} alt={t('createLink.clipboard')} />
                <Typography variant="button" className={classes.addToboardText}>
                  {added
                    ? t('createLink.addedToBoard')
                    : t('createLink.addToboard')}
                </Typography>
              </div>
            </button>
            <Typography variant="subtitle1" className={classes.socialTitle}>
              {t('shared.orShareVia')}
            </Typography>

            {/*   ----   social links  ---   */}
            <Grid container justifyContent="center" alignItems="center">
              <div className={classes.socialButton}>
                <SocialShareLink
                  onInitial={() =>
                    setShortenedLink({
                      trackingLink: '',
                      value: '',
                      commission: '',
                      isLoading: true,
                    })
                  }
                  onSuccess={data =>
                    setShortenedLink({
                      trackingLink: data.trackingLink,
                      value: data.shortUrl,
                      commission: data.commission,
                      isLoading: false,
                    })
                  }
                  onFail={() => setIsOpenSignInModal(true)}
                  shortenedLink={shortenedLink.value}
                  shortLinkData={{
                    url: product.product_url,
                    productId: productId,
                    productName: product.name,
                    productImage: product.thumbnail_url,
                  }}
                  isLoggedIn={isLoggedIn}
                >
                  {(ref, url) => (
                    <>
                      <EmailShareButton
                        ref={ref}
                        subject={t('createLink.emailSubject')}
                        body={t('createLink.emailBody')}
                        url={url}
                        style={{
                          display: 'none',
                        }}
                      >
                        <img
                          src={emailIcon}
                          alt={t('shared.email')}
                          className={classes.socialIcon}
                        />
                      </EmailShareButton>
                      <img
                        src={emailIcon}
                        alt={t('shared.email')}
                        className={classes.socialIcon}
                      />
                    </>
                  )}
                </SocialShareLink>
              </div>

              <div className={classes.socialButton}>
                <SocialShareLink
                  onInitial={() =>
                    setShortenedLink({
                      trackingLink: '',
                      value: '',
                      commission: '',
                      isLoading: true,
                    })
                  }
                  onSuccess={data =>
                    setShortenedLink({
                      trackingLink: data.trackingLink,
                      value: data.shortUrl,
                      commission: data.commission,
                      isLoading: false,
                    })
                  }
                  onFail={() => setIsOpenSignInModal(true)}
                  shortenedLink={shortenedLink.value}
                  shortLinkData={{
                    url: product.product_url,
                    productId: productId,
                    productName: product.name,
                    productImage: product.thumbnail_url,
                  }}
                  isLoggedIn={isLoggedIn}
                >
                  {ref => (
                    <>
                      <div
                        ref={ref}
                        style={{
                          display: 'none',
                        }}
                        onClick={handleSMSButtonClick}
                      >
                        <ChatBubbleOutlineOutlinedIcon />
                      </div>
                      <ChatBubbleOutlineOutlinedIcon />
                    </>
                  )}
                </SocialShareLink>
              </div>

              <div className={classes.socialButton}>
                <SocialShareLink
                  onInitial={() =>
                    setShortenedLink({
                      trackingLink: '',
                      value: '',
                      commission: '',
                      isLoading: true,
                    })
                  }
                  onSuccess={data =>
                    setShortenedLink({
                      trackingLink: data.trackingLink,
                      value: data.shortUrl,
                      commission: data.commission,
                      isLoading: false,
                    })
                  }
                  onFail={() => setIsOpenSignInModal(true)}
                  shortenedLink={shortenedLink.value}
                  shortLinkData={{
                    url: product.product_url,
                    productId: productId,
                    productName: product.name,
                    productImage: product.thumbnail_url,
                  }}
                  isLoggedIn={isLoggedIn}
                >
                  {(ref, url) => (
                    <>
                      <FacebookShareButton
                        ref={ref}
                        url={url}
                        quote={t('createLink.socialMessage')}
                        style={{
                          display: 'none',
                        }}
                      >
                        <img
                          src={facebookIcon}
                          alt={t('imageAlts.facebook')}
                          className={classes.socialIcon}
                        />
                      </FacebookShareButton>
                      <img
                        src={facebookIcon}
                        alt={t('imageAlts.facebook')}
                        className={classes.socialIcon}
                      />
                    </>
                  )}
                </SocialShareLink>
              </div>

              <div className={classes.socialButton}>
                <SocialShareLink
                  onInitial={() =>
                    setShortenedLink({
                      trackingLink: '',
                      value: '',
                      commission: '',
                      isLoading: true,
                    })
                  }
                  onSuccess={data =>
                    setShortenedLink({
                      trackingLink: data.trackingLink,
                      value: data.shortUrl,
                      commission: data.commission,
                      isLoading: false,
                    })
                  }
                  onFail={() => setIsOpenSignInModal(true)}
                  shortenedLink={shortenedLink.value}
                  shortLinkData={{
                    url: product.product_url,
                    productId: productId,
                    productName: product.name,
                    productImage: product.thumbnail_url,
                  }}
                  isLoggedIn={isLoggedIn}
                >
                  {(ref, url) => (
                    <>
                      <TwitterShareButton
                        ref={ref}
                        url={url}
                        title={t('createLink.socialMessage')}
                        style={{
                          display: 'none',
                        }}
                      >
                        <img
                          src={twiterIcon}
                          alt={t('imageAlts.twitter')}
                          className={classes.socialIcon}
                        />
                      </TwitterShareButton>
                      <img
                        src={twiterIcon}
                        alt={t('imageAlts.twitter')}
                        className={classes.socialIcon}
                      />
                    </>
                  )}
                </SocialShareLink>
              </div>

              <div className={classes.socialButton}>
                <SocialShareLink
                  onInitial={() =>
                    setShortenedLink({
                      trackingLink: '',
                      value: '',
                      commission: '',
                      isLoading: true,
                    })
                  }
                  onSuccess={data =>
                    setShortenedLink({
                      trackingLink: data.trackingLink,
                      value: data.shortUrl,
                      commission: data.commission,
                      isLoading: false,
                    })
                  }
                  onFail={() => setIsOpenSignInModal(true)}
                  shortenedLink={shortenedLink.value}
                  shortLinkData={{
                    url: product.product_url,
                    productId: productId,
                    productName: product.name,
                    productImage: product.thumbnail_url,
                  }}
                  isLoggedIn={isLoggedIn}
                >
                  {(ref, url) => (
                    <>
                      <PinterestShareButton
                        ref={ref}
                        url={product.trackingLink}
                        description={t('createLink.socialMessage')}
                        media={product.thumbnail_url}
                        style={{
                          display: 'none',
                        }}
                      >
                        <img
                          src={pinterest}
                          alt={t('imageAlts.pinterest')}
                          className={classes.socialIcon}
                        />
                      </PinterestShareButton>
                      <img
                        src={pinterest}
                        alt={t('imageAlts.pinterest')}
                        className={classes.socialIcon}
                      />
                    </>
                  )}
                </SocialShareLink>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <SignInModal
          handleClose={closeSignInModal}
          openStatus={isOpenSignInModal}
          setIsLoggedIn={setIsLoggedIn}
        />
      </div>
    </Layout>
  )
}

export default withTranslation()(ProductView)
