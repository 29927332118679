import axios from '../src/axios'
import { baseURL, BRANDS_NETWORK_TYPES, ENDPOINTS } from '../src/constants'

import { generateTrackingLink as generateTrackableLink } from 'chirpyest-toolkit'

interface ITrackingInfo {
  userId: number
  productId: string
  shoppingBoardItemId?: number
}

export const getBrands = async () => {
  return axios.get(`${baseURL}${ENDPOINTS.brandsList}?website=1`)
}

export const getBrandsLogosURLSrc = (
  apiData: any,
  categorizedBrandsLogos: any
) => {
  // filter apiData to brands that we have logos for
  const filteredBrands = apiData.filter((item: any) =>
    categorizedBrandsLogos.some((element: any) => element.id === item.brandId)
  )

  // append logos to our filtered data through using the logos array we already have
  const mappedForSrc = filteredBrands.map((element: any) => {
    element.src = categorizedBrandsLogos.find(
      (ele: any) => element.brandId === ele.id
    )?.src
    return element
  })
  return mappedForSrc
}

/**
 * chirpyestId : is a unique auto generated uuid to be used in case we don't have
 * anything unique to identify chirpyest tracking links. the extension uses this to detect and activate/deactivate cash back.
 */
const chirpyestId = '1eae3e2a-3ed0-476d-95bc-24806e69409e'

// This will generate tracking links for brands
export const generateTrackingLink = (
  item: any,
  { userId, userName }: any,
  productId: string = '',
  shoppingItemId: string = '',
  showRedirectingPage: boolean = false
) => {
  // console.log(userId, userName)
  const loggedInUserId = userId
  const network = BRANDS_NETWORK_TYPES[item.network]
  let trackedUser = `${userId || 0}`

  // some brands dont want to be known as giving cashback
  // and want to be kind of luxary brand
  // so we will not show the percent amount
  // and will not give user any cash back
  if (item.isCashbackShownInWebsite === false) trackedUser = '0'
  if (productId) trackedUser += `-p${productId}`
  if (shoppingItemId) trackedUser += `-s${shoppingItemId}`
  let result = item.url

  const targetLink = convertURLToStandardFormat(result)
  let productURL = new URL(targetLink)
  if (
    productURL.host === 'www.containerstore.com' &&
    (productURL.search.includes('p=') || productURL.search.includes('q='))
  ) {
    productURL.search.split('&').forEach(param => {
      if (param.includes('productId')) {
        result = productURL.origin + productURL.pathname + '?' + param
      }
    })
  }

  const linkParams = {
    network,
    originalUrl: result,
    trackingLink: item.trackingLink,
    userId: trackedUser,
  }

  try {
    result = generateTrackableLink(linkParams)
  } catch (err) {
    console.error('failed to generate tracking link', linkParams)
  }

  let formattedCommission = item.commission || ''
  if (formattedCommission?.includes('Fixed')) {
    formattedCommission = `$${Number(
      formattedCommission.split('Fixed ')[1]
    ).toFixed(2)}`
  }

  let siteUrl = process.env.GATSBY_SITE_URL
  let currentSiteHost = window.location.host

  if (!currentSiteHost.startsWith('www.')) {
    siteUrl = siteUrl?.replace('www.', '')
  }

  return !showRedirectingPage || item.isCashbackShownInWebsite === false
    ? result
    : `${siteUrl}/cashback?commission=${formattedCommission}&brandName=${item.brandName ||
        ''}&userId=${loggedInUserId || ''}&username=${userName ||
        ''}&trackingLink=${encodeURIComponent(result)}
        ${item.customText ? '&customText=' + item.customText : ''}
    `
}

const convertURLToStandardFormat = (url: any) => {
  if (url.includes('https://') || url.includes('http://')) {
    return url
  } else {
    return 'https://' + url
  }
}

export const urlSafeProductName = (
  productName: string,
  brandName: string
): string => {
  const lowerCaseBrandName = brandName?.toLowerCase()
  const lowerCaseName = productName?.toLowerCase()

  return `${lowerCaseName.replace(lowerCaseBrandName, '')}${
    lowerCaseBrandName ? `-${lowerCaseBrandName}` : ''
  }-chirpyest`
    .trim()
    .replace(/[éèêë]/g, 'e')
    .replace('ç', 'c')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/^-+|-+(?=-|$)/g, '')
    .toLowerCase()
}
